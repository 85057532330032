
import { LOCAL_STORAGE } from "@/core/constants/LocalStorage";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "InterviewResult",
  setup() {
    const answeredQuestions = ref(10); // Cevaplanan soru sayısı
    const time = ref<string | null>("00:00"); // Kalan süre

    const backToHome = () => {
      window.location.href = "/";
    };

    onMounted(() => {
      if(localStorage.getItem(LOCAL_STORAGE.TIMER))
      time.value = localStorage.getItem(LOCAL_STORAGE.TIMER)
    });

    return {
      answeredQuestions,
      time,
      backToHome,
    };
  },
});
